<template>
  <b-container>
    <ct-centered-spinner v-if="!loaded" height="16.25rem" />
    <div v-if="monitoringServiceActive && loaded" class="overview-card">
      <b-card no-body>
        <b-card-header header-tag="header" role="tab" @click="toggleCollapse">
          <h5 class="mb-0 d-flex justify-content-between align-items-center">
            Overview
            <b-icon :icon="isCollapsed ? 'chevron-down' : 'chevron-up'" />
          </h5>
        </b-card-header>
        <b-collapse id="overview-collapse" v-model="isCollapsed" accordion="overview" role="tabpanel">
          <b-card-body>
            <p>
              Trademark monitoring continuously scours the internet for potential infringement, 
              and sends you a detailed report each month.
            </p>
            <p>
              Setting up your monitoring service is simple—here's how it works:
            </p>
            <ol>
              <li>
                <strong>Add "Identities" to monitor:</strong>
                <p>
                  Think of an Identity as a way to keep tabs on possible ways your trademark may appear. Set up each
                  Identity, and we'll monitor it.
                </p>
                <monitoring-overview-timeline :items="timelineItems" />
              </li>
              <li>
                <strong>Review your monthly reports:</strong>
                <p>
                  Once your preferred Identities are set up, we'll send your monthly report to your client account
                  once
                  a month, viewable in the <router-link :to="{ name: 'monitoring-reports' }">Reports</router-link>
                  tab. This report identifies and details potential trademark
                  conflicts or threats, such as companies or domains using a similar name that could confuse your
                  customers and harm your business.
                </p>
              </li>
            </ol>
          </b-card-body>
        </b-collapse>
      </b-card>
      <div class="overview-card">
        <h3 class="header">Identities in your next report</h3>
        <div v-if="identities.length">
          <b-table hover :items="identities" :fields="fields" class="table">
            <template v-slot:head(value)>Identity</template>
            <template v-slot:cell(value)="data">{{ data.item.value }}</template>
            <template v-slot:head(type)>Type</template>
            <template v-slot:cell(type)="data">{{ identityType[data.item.type] }}</template>
            <template v-slot:head(actions)="">Actions</template>
            <template v-slot:cell(actions)="data">
              <b-button aria-label="Manage button" variant="primary" size="sm" @click="navigateToEdit(data.item)">
                Manage
              </b-button>
              <b-button 
                aria-label="Manage button" 
                variant="danger" 
                size="sm" 
                class="delete-button"
                @click="selectDeleteItem(data.item)"
              >
                Remove
              </b-button>
            </template>
          </b-table>
          <div>
            Monitoring {{ identities.length }} out of {{ monitoringIdentityMaxAllowed }} allotted Identities.
          </div>
        </div>
        <div v-else id="no-monitoring">
          You aren't currently monitoring any Identities.
          Select one of the Identity options above to get started!
        </div>
      </div>
    </div>
    <b-modal v-model="confirmDelete" hide-footer title="Are you sure you want to delete?">
      <b-button 
        class="mt-3" 
        variant="secondary" 
        block aria-label="Cancel button"
        @click="closeDeleteModal"
      >
        Cancel
      </b-button>
      <b-button class="mt-2" variant="danger" block aria-label="Confirm button" @click="deleteItem">Confirm</b-button>
    </b-modal>
  </b-container>
</template>

<script>
import MonitoringOverviewTimeline from '@/components/Monitoring/MonitoringOverviewTimeline.vue'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import { mapGetters, mapActions } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import Vue from 'vue'

export default {
  name: "MonitoringOverviewPage",
  components: {
    CtCenteredSpinner,
    MonitoringOverviewTimeline,
  },
  mixins: [makeToastMixin],
  data() {
    return {
      fields: ['value', 'type', 'actions'],
      identityType: {
        0: "Trademark (Text Only)",
        1: "Trademark (Text & Design)",
        2: "Trademark (Design Only)",
        3: "Domain",
        4: "Keywords",
        5: "Company",
      },
      bus: new Vue(),
      loaded: false,
      account_id: "ACCOUNT_ID",
      identities: [],
      confirmDelete: false,
      isCollapsed: true,
      itemToDelete: {},
      timelineItems: [
        { name: "Trademarks", route: "monitoring-trademarks", type: [0, 1, 2], monitored: false },
        { name: "Domains", route: "monitoring-domains", type: 3, monitored: false },
        { name: "Keywords", route: "monitoring-keywords", type: 4, monitored: false },
        { name: "Companies", route: "monitoring-companies", type: 5, monitored: false },
      ],
    }
  },
  computed: {
    ...mapGetters('monitoring', [
      'monitoringService',
      'monitoringServiceActive',
      'monitoringCandidates',
      'monitoringIdentities',
      'eligibleTrademarkStatus',
      'monitoringIdentityMaxAllowed',
    ]),
    ...mapGetters('account', ['actualAccountID']),
  },
  async mounted() {
    this.loaded = false
    await this.loadAccount()
    await this.fetchMonitoringIdentities(this.actualAccountID)
    await this.fetchMonitoringIdentityMaxAllowed(this.actualAccountID)
    this.identities = this.monitoringIdentities.filter((m) => m.active)

    this.updateMonitoredStatus()

    this.loaded = true
  },
  methods: {
    ...mapActions('monitoring', [
      'fetchMonitoringIdentities',
      'fetchMonitoringIdentityMaxAllowed',
      'removeMonitoringIdentity',
    ]),
    ...mapActions('account', ['loadAccount']),
    async create(name) {
      this.bus.$emit(name)
    },
    async navigateToEdit(item) {
      switch (item.type) {
        case 0:
        case 1:
        case 2:
          await this.$router.push({ name: 'monitoring-trademarks' })
          break
        case 3:
          await this.$router.push({ name: 'monitoring-domains' })
          break
        case 4:
          await this.$router.push({ name: 'monitoring-keywords' })
          break
        case 5:
          await this.$router.push({ name: 'monitoring-companies' })
          break
      }
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed
    },
    updateMonitoredStatus() {
      this.timelineItems.forEach(item => item.monitored = false)

      this.identities.forEach(identity => {
        this.timelineItems.forEach(item => {
          const itemTypes = Array.isArray(item.type) ? item.type : [item.type]
          if (itemTypes.includes(identity.type)) { item.monitored = true }
        })
      })
    },
    selectDeleteItem(item) {
      this.itemToDelete = item
      this.openDeleteModal()
    },
    openDeleteModal() {
      this.confirmDelete = true
    },
    closeDeleteModal() {
      this.confirmDelete = false
    },
    async deleteItem() {
      const item = this.itemToDelete
      try {
        await this.removeMonitoringIdentity({ monitoringIdentityID: item.id, accountID: this.actualAccountID })
        const index = this.identities.findIndex(d => d.id === item.id)
        if (index > -1) {
          this.identities.splice(index, 1)
        }
        this.updateMonitoredStatus()
        this.successToast('Success', "item deleted")
      } catch {
        this.errorToast('Error', "item failed to delete, please try again")
      } finally {
        this.closeDeleteModal()
      }
    },
  },
}
</script>

<style lang="scss">
.overview-card {
  .header {
    margin-bottom: 1em;
  }

  .table {
    border: 1px solid #cccee0;
    border-radius: 0.5em;
    background-color: white;
  }
}

.delete-button {
  margin-left: .5em;
}

#no-monitoring {
  margin-bottom: 1rem;
}
</style>
